import React, { useRef, useEffect } from 'react';
import { geoOrthographic, geoPath, geoCentroid } from 'd3-geo';
import { select, transition, drag, interpolate } from 'd3';
import countries from './countries.geo.json'; // Assuming the same geojson file
import './style.css';


const Globe = ({ visitedCountries, onHoverCountry, onSelectCountry }) => {
  const globeRef = useRef(null);
  const width = 500;
  const height = 500;

  useEffect(() => {
    const svg = select(globeRef.current);
    const projection = geoOrthographic().scale(250).translate([width / 2, height / 2]);
    const path = geoPath().projection(projection);

    // Draw the globe (water)
    svg.append('path')
      .datum({ type: 'Sphere' })
      .attr('class', 'water')
      .attr('d', path);

    // Draw countries
    svg.selectAll('path.land')
      .data(countries.features)
      .enter()
      .append('path')
      .attr('class', 'land')
      .attr('d', path)
      .each(function (d) {
        const countryElement = select(this);
        const isVisited = visitedCountries.some(country => country.id === d.id);

        if (isVisited) {
          countryElement
            .attr('class', 'visited')
            .on('mouseenter', () => 
              {
                onHoverCountry(d);
                onSelectCountry(d);
              })
            .on('mouseleave', () => onHoverCountry(null))
            .on('click', () => 
              {
                focusOnCountry(d.id, projection, svg, path);
                onSelectCountry(d);
              });
        }
      });

    // Drag functionality
    svg.call(
      drag().on('drag', (event) => {
        const rotate = projection.rotate();
        const k = 75 / projection.scale();
        projection.rotate([rotate[0] + event.dx * k, rotate[1] - event.dy * k]);
        svg.selectAll('path').attr('d', path);
      })
    );
  }, [visitedCountries]);

  const focusOnCountry = (id, projection, svg, path) => {
    const country = countries.features.find(c => c.id === id);
    if (country) {
      const centroid = geoCentroid(country);
      rotateGlobe(centroid, projection, svg, path);
    }
  };

  const rotateGlobe = (p, projection, svg, path) => {
    transition()
      .duration(1000)
      .tween('rotate', () => {
        const interpolator = interpolate(projection.rotate(), [-p[0], -p[1]]);
        return (t) => {
          projection.rotate(interpolator(t));
          svg.selectAll('path').attr('d', path);
        };
      });
  };

  return <svg id="globe" ref={globeRef} viewBox={`0 0 ${width} ${height}`} />;
};

export default Globe;
