import React, { useState } from 'react';
import Globe from '../components/travel/Globe';
import ReactCountryFlag from 'react-country-flag';
import AnimatedPage from '../components/animatedPage/AnimatedPage';


const visitedCountries = [
  { id: 'PRT', name: 'Portugal', code: 'PT' },
  { id: 'ESP', name: 'Spain', code: 'ES' },
  { id: 'FRA', name: 'France', code: 'FR' },
  { id: 'ITA', name: 'Italy', code: 'IT' },
  { id: 'DEU', name: 'Germany', code: 'DE' },
  { id: 'POL', name: 'Poland', code: 'PL' },
  { id: 'KAZ', name: 'Kazakhstan', code: 'KZ' },
  { id: 'UZB', name: 'Uzbekistan', code: 'UZ' },
  { id: 'HUN', name: 'Hungary', code: 'HU' },
  { id: 'CZE', name: 'Czech Republic', code: 'CZ' },
  { id: 'SVK', name: 'Slovakia', code: 'SK' },
  { id: 'AUT', name: 'Austria', code: 'AT' },
  { id: 'GRC', name: 'Greece', code: 'GR' },
  { id: 'CYP', name: 'Cyprus', code: 'CY' },
  { id: 'TUR', name: 'Turkey', code: 'TR' },
  { id: 'SWE', name: 'Sweden', code: 'SE' },
  { id: 'FIN', name: 'Finland', code: 'FI' },
  { id: 'NOR', name: 'Norway', code: 'NO' },
  { id: 'EST', name: 'Estonia', code: 'EE' },
  { id: 'LTU', name: 'Lithuania', code: 'LT' },
  { id: 'LVA', name: 'Latvia', code: 'LV' },
  { id: 'ISR', name: 'Israel', code: 'IL' },
  { id: 'KGZ', name: 'Kyrgyzstan', code: 'KG' }
];

const Travels = () => {
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);

  console.log("Selected Country : ", selectedCountry);

  return (
    <>
      <AnimatedPage>
        <main className="section">
          <div className="container">
            <div className="globe-container">
              <Globe visitedCountries={visitedCountries} onHoverCountry={setHoveredCountry} onSelectCountry={setSelectedCountry} />

              <div className="globe-selected-country-description">
                <div className="title-2">
                  {
                    selectedCountry ?
                      <>
                        {selectedCountry.properties.name}<ReactCountryFlag
                          countryCode={visitedCountries.find((country) => country.id === selectedCountry.id).code}
                          svg
                        />
                      </>
                      :
                      hoveredCountry ?
                        <>
                          {hoveredCountry.properties.name}
                          <ReactCountryFlag
                            countryCode={visitedCountries.find((country) => country.id === hoveredCountry.id).code}
                            svg
                          />
                        </>
                        :
                        'Hover over a country!'
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
      </AnimatedPage>
    </>
  );
}

export default Travels;