import Project from '../components/project/Project';
import {projects} from "./../helpers/projectsList";
import AnimatedPage from '../components/animatedPage/AnimatedPage';

const Projects = () => {
	return (
		<AnimatedPage>
		<main className="section">
			<div className="container">
				<h2 className="title-1">Projects</h2>
				<ul className="projects">
					{projects.map((project, index) => {
						return (
							<Project
								key={index}
								title={project.title}
								img={project.img}
								index={index}
								video={project.video || null}
							/>
						);
					})}
				</ul>
			</div>
		</main>
		</AnimatedPage>
	);
};

export default Projects;
