import ParticlesComponentTest from "../particles/ParticleTest";
import "./style.css";

const Header = () => {
    return (
		<div className="main__header__wrapper">
		<ParticlesComponentTest id="tsparticles"/>	
		<header className="header">
			<div className="header__wrapper">
				<h1 className="header__title">
					<strong>
						Hi, my name is <em>Pedro</em>
					</strong>
					<br />a MSC in Informatics and Computing Engineering
				</h1>
				<div className="header__text">
					<p>with passion for data science and software engineering.</p>
				</div>
				<a href="/cv.pdf" className="btn" target="_blank">
					View CV
				</a>
				
			</div>
			
		</header> 
		</div>
		
	);
}

export default Header;