import { NavLink } from 'react-router-dom';
import { useState } from "react";
import './style.css';

const Project = ({ title, img, index }) => {
	const [hover, setHover] = useState(false);
	return (
		<NavLink to={`/project/${index}`}>
			<li className="project"
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}>

				{/* {hover && 
				<div className="project__overlay">
					Thesis overview</div>} */}
				<img src={img} alt={title} className="project__img" />
				<h3 className="project__title">{title}</h3>
			
			
			</li>
		</NavLink>
	);
};

export default Project;
