import {useParams} from "react-router-dom";
import BtnGitHub from "../components/btnGitHub/BtnGitHub";
import {projects} from "./../helpers/projectsList";
import Markdown from "react-markdown";
import ReactMarkdown from "react-markdown";



const Project = () => {
	const {id} = useParams();
	const project = projects[id];


    return (
		<main className="section">
			<div className="container">
				<div className="project-details">
					<h1 className="title-1">{project.title}</h1>

					<img
						src={project.imgBig}
						alt={project.title}
						className="project-details__cover"
					/>

					<div className="project-details__desc">
						<p>Skills: {project.skills}</p>
					</div>

					{/* <div className="project-details__fulldesc">
						{project.description}
					</div> */}

					<div className="project-details__fulldesc" dangerouslySetInnerHTML={{ __html: project.description }} />

					{project.video && 
					<div className="project-details__video">
						<h2>Video Showcase</h2>
						<div className="project-details__iframewrapper">
							<iframe
							 src={project.video}
							 allowFullScreen
							 title='video'
							/>
						</div>
					</div>
					}

					{project.gitHubLink && (
						<BtnGitHub link={project.gitHubLink} />
					)}
				</div>
			</div>
		</main>
	);
}

export default Project;