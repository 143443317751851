import Header from './../components/header/Header'
import AnimatedPage from '../components/animatedPage/AnimatedPage';

const Home = () => {
    return (
		<>
		<AnimatedPage>
			<Header />
			<main className="section">
				<div className="container">
					<div className="aboutMe">
						<div className="aboutMeLeft">
							<h2 className="title-2">About Me</h2>
							<p>Hello! I'm Pedro, a 23-years-old young adult based in Porto, Portugal. I hold a Bachelor's and a Master's degree from the Faculty of Engineering of 
								University of Porto in Informatics and Computing Engineering. My main interests reside in machine learning, software development and math. I’m a very motivated and goal oriented person, always up to new challenges and things to learn, be it in this areas or other fields!
							</p>
							<br></br>
							<p>I have some work experience, first as a Intern in <b>Checkmarx</b>, where I delved in developing a Dashboard for monitoring and visualizing vulnerabilities in packages. There I got to experience high quality code, in a microservices environment, using cutting edge technologies such as .NET, ElasticSearch and GraphQL.</p>
							<p>Currently, I work as a Junior Software Engineer at <b>Armis Group</b>, where I mostly do FullStack to deliver solutions for various different clients, mainly in React, .NET and Miscrosoft SQL Server.</p>
							<br></br>
							<p>Outside of work, I'm a sucker for traveling, history, politics and exercise. I absolutely love anything related to the gym, currently being one of my main hobbies.</p>
						</div>
						<div className='aboutMeRight'>
							<img src="me.jpg" alt="Pedro's photo" />
							<p className="image-caption">Me in <a href="https://en.wikipedia.org/wiki/Almaty" target="_blank">Almaty</a> Metro station</p>
						</div>
					</div>
					{/* <ul className="content-list">
						<li className="content-list__item">
							<h2 className="title-2">Frontend</h2>
							<p>
								JavaScript, ReactJS, Angular, Redux,
								HTML, CSS, NPM, BootStrap, CSHtml
							</p>
						</li>
						<li className="content-list__item">
							<h2 className="title-2">Backend</h2>
							<p>NodeJS, MySQL, MongoDB, PHP, Laravel</p>
						</li>
					</ul> */}
				</div>
			</main>
		</AnimatedPage>
		</>
	);
}

export default Home;